<template>
  <div class="visibility-setting">
    <div v-if="initLoading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>
    <div v-else>
      <b-row class="contenu">
        <b-col class="groupe" col xs="12" sm="6" md="5">
          <h4>Filiales</h4>
          <div class="block" v-if="teamsfiliale && teamsfiliale.length">
            <b-list-group>
              <b-list-group-item
                href="#"
                :class="filiale.visible ? '' : 'collapsed'"
                :aria-controls="'collapse-' + index"
                @click="filiale.visible = !filiale.visible"
                v-for="(filiale, index) in teamsfiliale"
                :key="index"
                :value="index.id"
              >
                <div class="filiale">
                  <div class="icon">
                    <font-awesome-icon icon="angle-down" />
                  </div>
                  <div href="#" class="titrefiliale">
                    {{ filiale.name }}
                  </div>
                </div>
                <b-collapse
                  :id="'collapse-' + index"
                  v-model="filiale.visible"
                  class="collapse"
                >
                  <div
                    v-if="
                      filiale.depots &&
                        filiale.depots.data &&
                        filiale.depots.data.length
                    "
                  >
                    <b-list-group>
                      <b-list-group-item
                        class="depots"
                        v-for="depot in filiale.depots.data"
                        :key="depot.id"
                        :value="depot.id"
                        button
                        @click.prevent.stop="handleClickDepots(depot.id)"
                      >
                        <div>{{ depot.name }}</div>
                        <div class="icon" v-if="selectedDepot == depot.id">
                          <font-awesome-icon icon="check" />
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div v-else class="Aucundepots">
                    Aucun Dépôt
                  </div>
                </b-collapse>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div v-else class="Aucunfiliale">Aucun Filiale</div>
        </b-col>
        <b-col class="utilisateur" col xs="12" sm="6" md="7">
          <h4>Utilisateurs</h4>
          <div class="select">
            <multiselect
              v-model="selectedUser"
              :options="computedAvailableUsers"
              @select="handleSelect"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Rechercher l'utilisateur"
              :custom-label="nameWithLang"
              label="name"
              track-by="id"
              :preselect-first="false"
            >
            </multiselect>
          </div>
          <form v-if="selectedDepot">
            <div class="listContenu">
              <div class="user-list-header">
                <div v-if="loading" class="three-dots-loading">
                  Chargement en cours
                </div>
                <div v-else></div>
                <div class="icon">
                  <div class="icon1" title="Lire">
                    <font-awesome-icon icon="eye" />
                  </div>
                  <div class="icon2" title="Ecrire">
                    <font-awesome-icon icon="pencil-alt" />
                  </div>
                </div>
              </div>
              <b-list-group v-if="!loading">
                <b-list-group-item
                  class="titre"
                  v-for="user in selectedUsers"
                  :key="user.id"
                >
                  <div class="user">
                    {{ user.first_name }} {{ user.last_name }}
                    <strong class="type">({{ user.type | typeFormat }})</strong
                    ><br />
                    <strong class="type" v-if="user.team">{{
                      user.team
                    }}</strong>
                    <strong class="type" v-else>(sans équipe)</strong>
                  </div>

                  <div class="checkbox">
                    <b-form-checkbox
                      class="checkbox1"
                      :checked="user.can_see === 1"
                      :disabled="user.disabled === 1"
                      @change="handleChangeChekboxRead(user.id)"
                    >
                    </b-form-checkbox>
                    <b-form-checkbox
                      class="checkbox2"
                      :checked="user.can_edit === 1"
                      :disabled="user.disabled === 1"
                      @change="handleChangeChekboxWrite(user)"
                    >
                    </b-form-checkbox>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Visiblity',
  data() {
    return {
      selectedDepot: null,
      selectedUser_id: null,
      availableUsers: [],
      selectedUsers: [],
      selectedUser: null,
      selectedCheckbox: null,
      initLoading: true,
      loading: false,
      error: null
    }
  },
  methods: {
    ...mapActions([
      'getUsersVisibilityByDepot',
      'getteamsfiliale',
      'getAvailableVisibilityUsersByDepot',
      'addUtilisateur',
      'updateCanSee',
      'updateCanEdit'
    ]),

    nameWithLang({ name, type }) {
      let formatType = ''
      switch (type) {
        case 'user.admin':
          formatType = 'Utilisateur Admin'
          break
        case 'user.agent':
          formatType = 'Agent'
          break
        case 'user.resp.plan':
          formatType = 'Responsable Planning'
          break
        case 'user.supervisor':
          formatType = 'Régie Superviseur'
          break
        case 'user.commercial':
          formatType = 'Régie Commercial'
          break
        case 'user.final':
          formatType = 'Régie Utilisateur Final'
          break
        case 'user.technicien':
          formatType = 'Filiale Poseur'
          break
        case 'user.administration':
          formatType = 'CERTIGAIA Administration'
          break
        case 'user.client':
          formatType = 'Client'
          break
        case 'user.commercial.ite':
          formatType = 'AMO'
          break
        case 'user.sub-super-admin':
          formatType = 'Sous Super Admin'
          break
        case 'user.ingenieur':
          formatType = 'Ingénieur'
          break
        case 'user.visiteur.tech':
          formatType = 'Visiteur Technique'
          break
        case 'user.tech.travaux':
          formatType = 'Technicien Travaux'
          break
        default:
          formatType = ''
          break
      }
      return `${name} (${formatType})`
    },
    async handleClickDepots(depot_id) {
      this.loading = true
      this.selectedDepot = depot_id
      this.availableUsers = []
      this.availableUsers = await this.getAvailableVisibilityUsersByDepot({
        id: depot_id,
        exclude: [
          'team',
          'technicianPipe',
          'visibleTeams',
          'commercialItePipe',
          'userPipe'
        ]
      })
      this.selectedUsers = []
      this.selectedUsers = await this.getUsersVisibilityByDepot(depot_id)
      this.loading = false
    },
    async handleChangeChekboxRead(user_id) {
      this.selectedCheckbox = await this.updateCanSee({
        depot_id: this.selectedDepot,
        user_id: user_id,
        can_see: this.selectedUsers.can_see === 1 ? 1 : 0
      })
      this.selectedUsers = await this.getUsersVisibilityByDepot(
        this.selectedDepot
      )
      this.availableUsers = await this.getAvailableVisibilityUsersByDepot({
        id: this.selectedDepot,
        exclude: [
          'team',
          'technicianPipe',
          'visibleTeams',
          'commercialItePipe',
          'userPipe'
        ]
      })
    },
    async handleChangeChekboxWrite(user) {
      this.selectedCheckbox = await this.updateCanEdit({
        depot_id: this.selectedDepot,
        user_id: user.id,
        can_edit: user.can_edit === 1 ? 0 : 1
      })
      this.selectedUsers = await this.getUsersVisibilityByDepot(
        this.selectedDepot
      )
    },
    async handleSelect(e) {
      this.loading = true
      this.error = null
      const response = await this.addUtilisateur({
        depot_id: this.selectedDepot,
        user_id: e.id
      })
      this.loading = false
      if (response.success) {
        this.selectedUser = null
        this.selectedUsers = await this.getUsersVisibilityByDepot(
          this.selectedDepot
        )
        this.availableUsers = await this.getAvailableVisibilityUsersByDepot({
          id: this.selectedDepot,
          exclude: [
            'team',
            'technicianPipe',
            'visibleTeams',
            'commercialItePipe',
            'userPipe'
          ]
        })
      } else {
        this.loading = false
        this.error = response.error
      }
    }
  },
  computed: {
    ...mapGetters(['teamsfiliale']),

    computedAvailableUsers: function() {
      return this.availableUsers.filter(
        user => this.selectedUsers.findIndex(item => item.id === user.id) < 0
      )
    }
  },
  async mounted() {
    await this.getteamsfiliale({
      exclude: [
        'depots.accessedByUsers',
        'depots.commercialsIte',
        'depots.departments',
        'depots.editibleByUsers',
        'depots.poseurs',
        'depots.team',
        'depots.visibleByUsers',
        'manager',
        'members'
      ]
    })
    this.initLoading = false
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'user.admin':
          return 'Utilisateur Admin'
        case 'user.agent':
          return 'Agent'
        case 'user.resp.plan':
          return 'Responsable Planning'
        case 'user.supervisor':
          return 'Régie Superviseur'
        case 'user.commercial':
          return 'Régie Commercial'
        case 'user.final':
          return 'Régie Utilisateur Final'
        case 'user.technicien':
          return 'Filiale Poseur'
        case 'user.administration':
          return 'CERTIGAIA Administration'
        case 'user.client':
          return 'Client'
        case 'user.commercial.ite':
          return 'AMO'
        case 'user.sub-super-admin':
          return 'Sous Super Admin'
        case 'user.ingenieur':
          return 'Ingénieur'
        case 'user.visiteur.tech':
          return 'Visiteur Technique'
        case 'user.tech.travaux':
          return 'Technicien Travaux'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.visibility-setting {
  .header {
    position: relative;
  }
  .groupe {
    .block {
      .list-group {
        .list-group-item {
          .filiale {
            padding-bottom: 10px;
            .titrefiliale {
              display: inline-block;
              margin-left: 10px;
              font-weight: bold;
              text-transform: capitalize;
            }
            .icon {
              display: inline-block;
            }
          }

          .depots {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            color: #2dabe2;
            text-transform: capitalize;
          }
          .Aucundepots {
            color: #2dabe2;
            margin-left: 30px;
          }
        }
      }
    }
    .Aucunfiliale {
      color: #2dabe2;
      margin-left: 30px;
      padding: 13px;
    }
  }
  .utilisateur {
    .listContenu {
      .user-list-header {
        display: flex;
        padding-bottom: 10px;
        padding-top: 10px;
        align-items: center;
        justify-content: space-between;
        .loading {
          margin-right: 176px;
        }
        .icon {
          display: flex;
          padding-bottom: 10px;
          padding-top: 10px;
          margin-right: 29px;
          .icon1 {
            margin-right: 2px;
          }

          .icon2 {
            margin-left: 30px;
          }
        }
      }
      .titre {
        display: flex;
        justify-content: space-between;
        .user {
          .type {
            color: #2dabe2;
            font-weight: normal;
            font-size: 12px;
            text-transform: capitalize;
          }
        }
        .checkbox {
          display: flex;
          .checkbox1 {
            margin-right: 3px;
          }
          .checkbox2 {
            margin-left: 20px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    .utilisateur {
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }
}
</style>
